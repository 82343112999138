import axios from '@/axios'
import { mapHelper } from 'common/utils'
import {
  getRommTypesUrl
} from './api'
// 获取房屋类型数据

const getHouseTypeData = async params => {
  let list = []
  const { data, status } = await axios.get(getRommTypesUrl, { params })
  if (status === 100) {
    list = data || []
    list = list.map(item => {
      return {
        ...item,
        text: item.value,
        value: item.code
      }
    })
  }

  const {
    map: houseTypeMap,
    setOps: houseTypeOps
  } = mapHelper.setMap(list)

  return {
    houseTypeMap,
    houseTypeOps
  }
}

export {
  getHouseTypeData
}
